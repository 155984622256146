import React from "react"


import AltLayout from "../components/altlayout"
import SEO from "../components/seo"

import LotTwo from "../components/Lots/lottwo"
import Footer from "../components/Footer/footer"


const LotTwoPage = () => (
  <AltLayout>
    <SEO title="Lot" />
    <LotTwo/>
    <Footer />
  </AltLayout>
)

export default LotTwoPage
