import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"


import LotHeader from "../LotHeader/lotheader"
import LotIntro from "../LotIntro/lotintro"
import LotDisclaimer from "../LotDisclaimer/lotdisclaimer"
import LotAreas from "../LotAreas/lotareas"
import LotVideo from "../LotVideo/lotvideo"
import LotGallery from "../LotGallery/lotgallery"
import LotPlans from "../LotPlans/lotplans"
import LotElevationMap from "../LotElevationMap/lotelevationmap"
import LotRenders from "../LotRenders/lotrenders"
import LotData from "../../Data/lot-2.json"

const Container = styled.div`

`

function LotTwo(){
    const data = useStaticQuery(graphql`
    query {
      one_bg: file(relativePath: { eq: "render.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1220) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      main: file(relativePath: { eq: "lot-2.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      p_one: file(relativePath: { eq: "4co1.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      p_two: file(relativePath: { eq: "4co3.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      p_three: file(relativePath: { eq: "4co2.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      p_four: file(relativePath: { eq: "4co4.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      p_five: file(relativePath: { eq: "4co5.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      p_six: file(relativePath: { eq: "4co6.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      r_one: file(relativePath: { eq: "render.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      r_two: file(relativePath: { eq: "render_6_1.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      r_three: file(relativePath: { eq: "render_6_2.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      r_four: file(relativePath: { eq: "render_6_5.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      r_five: file(relativePath: { eq: "render_6_4.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      r_six: file(relativePath: { eq: "render_6_6.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      r_seven: file(relativePath: { eq: "render_6_7.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      r_eight: file(relativePath: { eq: "render_6_8.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      r_nine: file(relativePath: { eq: "render_6_9.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      r_ten: file(relativePath: { eq: "render_6_10.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      f_one: file(relativePath: { eq: "f_4_1.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      f_two: file(relativePath: { eq: "f_4_2.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1120) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
    return(
        <Container >
            <LotHeader name="4 Creekside Oaks" bg={data.one_bg.childImageSharp.fluid} />
            <LotDisclaimer />
            <LotIntro img={data.main.childImageSharp.fluid} desc={LotData.long_desc} />
            <LotGallery data={data} />
            <LotRenders data={data}/>
            <LotVideo videoId="409076250" />
            <LotPlans img={data} />
            <LotAreas src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d50326.417160477766!2d-122.16565382114902!3d37.96776840880548!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808564f861dbe223%3A0x9dcc6fc793048ded!2s4%20Creekside%20Oaks%2C%20Martinez%2C%20CA%2094553!5e0!3m2!1sen!2sus!4v1586591182117!5m2!1sen!2sus" />
        </Container>
    )
}

export default LotTwo
